'use client';

import CompactLayout from '@/layouts/compact/layout';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function Layout({ children }: Props) {
  return (
    // <GuestGuard>
    <CompactLayout
      enabledBack
      title="¡Encantado de verte!"
      subtitle="Inicia sesión para acceder a Intelli Market"
    >
      {children}
    </CompactLayout>
    // </GuestGuard>
  );
}
